import { RequestMainService } from "../request";
import { CookieService, ECookieVariable } from "../cookie";
import { ObjectUtils } from "../../modules";
import moment from "moment";
import _ from "lodash";

export class AdminService {
  static async getListUser(params: any = {}) {
    return RequestMainService.get(`/admin/user`, params)
      .then(({ result }) => ({
        count: result.total,
        data: result.users,
      }))
      .catch(() => ({
        count: 0,
        data: [],
      }));
  }

  static getListUserStatus() {
    return [
      // { label: "KYC_CONFIRMED", value: "KYC_CONFIRMED" },
      { label: "EMAIL VERIFIED", value: "EMAIL_VERIFIED" },
      { label: "JUST REGISTER", value: "JUST_REGISTERED" },
      { label: "AFF REQUIRED", value: "AFF_REQUIRED" },
    ];
  }

  static async getUserDetailByEmail(email: string) {
    return await RequestMainService.get(`/user/public-info/${email}`);

    // return {
    //     userInfo: userInfoRes.result,
    // };
  }

  static async getUserDetail(userId: number) {
    return RequestMainService.get(`/admin/get-user?userId=${userId}`);
  }

  static async getListTransactions(params: any = {}) {
    return RequestMainService.get(`/admin/transaction`, params)
      .then(({ result }) => ({
        count: result?.total,
        data: result?.transactions,
        total: result?.totalValue,
        coinActive: result?.transactions?.[0]?.coinId,
      }))
      .catch(() => ({
        count: 0,
        data: [],
        total: 0,
        coinActive: null,
      }));
  }

  static async getListTransactionsAdminWithdraw(params: any = {}) {
    return RequestMainService.get(`/admin/transaction-admin-withdraw`, params);
  }

  static async getMarketInfo(coinId: any) {
    return RequestMainService.get(`/market-info/${CookieService.get(ECookieVariable.USER_SYMBOL_ACTIVE_ID)}/${coinId}`).then(({ result }) => result);
  }

  static async getPendingKYC() {
    return RequestMainService.get(`/admin/kycs/pending`).then(({ result }) => ({
      count: result.length,
      data: result,
    }));
  }

  static async getListWithdrawProgress(params: any = {}) {
    return RequestMainService.get(`/admin/withdraw/history`, params).then(({ result }) => {
      // const data = result.withdrawProgresses.filter((v: any) => v.status === "JUST_CREATED");
      return {
        count: result.total,
        data: result.withdrawProgresses,
      };
    });
  }

  static async interventionLimit(amount: number) {
    return RequestMainService.post(`/game-setting/intervention-limit`, {
      interventionLimit: amount,
    });
  }

  static async budgetAmount(amount: number) {
    return RequestMainService.post(`/game-setting/budget-limit`, {
      budgetAmount: amount,
    });
  }

  static async budgetPercent(budgetPercent: number) {
    return RequestMainService.post(`/game-setting/budget-percent`, {
      budgetPercent: budgetPercent,
    });
  }

  static async getBudgetAmount() {
    return RequestMainService.get(`/game-setting/budget-limit`).then((res) => res.result);
  }

  static async getBudgetPercent() {
    return RequestMainService.get(`/game-setting/budget-percent`).then((res) => res.result);
  }

  static async getInterventionLimit() {
    return RequestMainService.get(`/game-setting/intervention-limit`).then((res) => res.result);
  }

  static async getAffiliation(numberOflevelsFromRoot: any, rootUserId: any) {
    return RequestMainService.get(`/tree/${numberOflevelsFromRoot}/${rootUserId}?isHideBalance=true`).then((res) => res.result);
  }

  static async getFranchiseUserReport(numberOflevelsFromRoot: any, rootUserId: any, params: any = {}) {
    const offset = ObjectUtils.getIn(params, "offset", 0);
    const limit = ObjectUtils.getIn(params, "limit", 10);

    return RequestMainService.get(`/report-tree/${numberOflevelsFromRoot}/${rootUserId}`).then((res) => {
      let data = [...res.result];
      if (typeof params.q === "string")
        data = data.filter((v) => {
          return (
            `${v.firstName} ${v.lastName}`.toLowerCase().search(params.q.toLowerCase()) > -1 ||
            v.email.toLowerCase().search(params.q.toLowerCase()) > -1
          );
        });

      const paramKeys = ["wonVolume", "lostVolume", "rankCommission", "directCommission", "cashBack", "agencyCount"];

      paramKeys.map((sortKey) => {
        if (params[sortKey]) {
          if (params[sortKey] === "increase") data = data.sort((a, b) => a[sortKey] - b[sortKey]);
          if (params[sortKey] === "descrease") data = data.sort((a, b) => b[sortKey] - a[sortKey]);
        }

        return sortKey;
      });

      return {
        data: data.slice(offset, offset + limit),
        count: data.length,
        full: data,
      };
    });
  }

  static async getFranchiseLeaderReport(numberOflevelsFromRoot: any, rootUserId: any, params: any = {}) {
    const offset = ObjectUtils.getIn(params, "offset", 0);
    const limit = ObjectUtils.getIn(params, "limit", 10);

    return RequestMainService.get(`/leader-tree/${numberOflevelsFromRoot}/${rootUserId}`).then((res) => {
      let data = [...res.result];
      if (typeof params.q === "string")
        data = data.filter((v) => {
          return (
            `${v.firstName} ${v.lastName}`.toLowerCase().search(params.q.toLowerCase()) > -1 ||
            v.email.toLowerCase().search(params.q.toLowerCase()) > -1
          );
        });

      const paramKeys = ["wonVolume", "lostVolume", "rankCommission", "directCommission", "cashBack", "agencyCount"];

      paramKeys.map((sortKey) => {
        if (params[sortKey]) {
          if (params[sortKey] === "increase") data = data.sort((a, b) => a[sortKey] - b[sortKey]);
          if (params[sortKey] === "descrease") data = data.sort((a, b) => b[sortKey] - a[sortKey]);
        }

        return sortKey;
      });

      return {
        data: data.slice(offset, offset + limit),
        count: data.length,
        full: data,
      };
    });
  }

  static async getFanshiseLeaderUserReport(userId: number, params: any = {}) {
    return RequestMainService.get(`/admin/branch-report/${userId}`, params).then(({ result }) =>
      result.sort((a: any, b: any) => b.startAt - a.startAt)
    );
  }

  static async getSytemReport(params: any = {}) {
    return RequestMainService.get(`/admin/system-report`, params).then((res) => res.result);
  }

  static async kycApprove(kycId: string) {
    return RequestMainService.post(`/admin/kyc/approve/${kycId}`);
  }

  static async kycReject(kycId: string) {
    return RequestMainService.post(`/admin/kyc/reject/${kycId}`);
  }

  static async withdrawApprove(withdrawId: string) {
    return RequestMainService.post(`/admin/withdraw/accept/${withdrawId}`);
  }

  static async withdrawReject(withdrawId: string) {
    return RequestMainService.post(`/admin/withdraw/reject/${withdrawId}`);
  }

  static async setRank(userId: number, rank: number) {
    return RequestMainService.post(`/privilege`, { userId, rank });
  }

  static async freezeUsers(userIds: number[], secretCode: string) {
    return RequestMainService.post(`/admin/freeze-user`, { userIds, secretCode });
  }

  static async unfreezeUsers(userIds: number[], secretCode: string) {
    return RequestMainService.post(`/admin/unfreeze-user`, { userIds, secretCode });
  }

  static async enableUsersTwoFa(userIds: number[]) {
    return RequestMainService.post(`/admin/enable-user-two-fa`, {
      userIds,
    });
  }

  static async disableUsersTwoFa(userIds: number[]) {
    return RequestMainService.post(`/admin/disable-user-two-fa`, {
      userIds,
    });
  }

  static async freezeUsersTransfer(userIds: number[]) {
    return RequestMainService.post(`/admin/freeze-user-transfer`, {
      userIds,
    });
  }

  static async unfreezeUsersTransfer(userIds: number[]) {
    return RequestMainService.post(`/admin/unfreeze-user-transfer`, {
      userIds,
    });
  }

  static async freezeUsersWithdraw(userIds: number[], secretCode: string) {
    return RequestMainService.post(`/admin/freeze-user-withdraw`, {
      userIds,
      secretCode,
    });
  }

  static async unfreezeUsersWithdraw(userIds: number[], secretCode: string) {
    return RequestMainService.post(`/admin/unfreeze-user-withdraw`, {
      userIds,
      secretCode,
    });
  }

  static async updateProfile(data: any) {
    return RequestMainService.put(`/admin/profile-user`, { ...data });
  }

  static async supportDeposit(blockNumberList: string) {
    return RequestMainService.post(`/admin/deposit/support`, {
      blockNumberList,
    });
  }

  static async startWithdrawToAdminWallet() {
    return RequestMainService.post(`/admin/run-withdraw-for-admin`, {});
  }

  static async getListExperienceGame(params: any = {}) {
    return RequestMainService.get(`/admin/experience-game`, params).then(({ result }) => ({
      count: result.total,
      data: result.data,
      totalPackage: result?.totalPackage,
      totalUser: result?.totalUser,
    }));
  }

  static async getListRequestClaim(params: any = {}) {
    return RequestMainService.get(`/admin/request-claim`, params).then(({ result }) => ({
      count: result.total,
      data: result.data,
      totalRefund: result.totalRefund,
    }));
  }

  static async handleRequestClaim(payload: any) {
    return RequestMainService.put(`/admin/request-claim`, payload);
  }

  static async getListAffilateTable(params: any = {}) {
    if (params.fromDate) params.fromDate = moment(params.fromDate).utcOffset(0, true).toISOString();
    if (params.toDate) params.toDate = moment(params.toDate).utcOffset(0, true).toISOString();
    return RequestMainService.get(`/admin/affilate-table`, params).then(({ result }) => ({
      count: result.total,
      data: result.data,
    }));
  }

  static async getListLevelSettingHistory(params: any = {}) {
    params.fromDate = moment(params.fromDate).toISOString();
    params.toDate = moment(params.toDate).toISOString();
    return RequestMainService.get(`/admin/affiliation-rank`, params).then(({ result }) => ({
      count: result.total,
      data: result.data,
    }));
  }

  static async setLevel(userId: number, rank: number) {
    return RequestMainService.put(`/admin/update-rank`, { userId, rank });
  }

  static async getOrdersForClaimRequest(experienceGameId: any) {
    return RequestMainService.get(`/admin/experience-game/${experienceGameId}/bet`)
      .then(({ result }) => ({
        count: result?.length,
        data: result,
      }))
      .catch((err) => ({
        count: 0,
        data: [],
      }));
  }

  static async ChangePassword(payload: any) {
    return RequestMainService.put(`/admin/change-password`, payload);
  }

  static async getListSupplier(params: any = {}) {
    return RequestMainService.get(`/admin/suppliers`, params).then(({ result }) => ({
      count: result.total,
      data: result.data,
    }));
  }
  static async getSupplierDetail(supplierId: number) {
    return RequestMainService.get(`/admin/suppliers/${supplierId}`);
  }
  static async activeSupplier(supplierId: any, value: any) {
    return RequestMainService.put(`/admin/suppliers/${supplierId}/${value}`);
  }

  static async updateSupplier(data: any) {
    return RequestMainService.put(`/admin/suppliers/${data.supplierId}`, { ...data });
  }
  static async addSupplier(data: any) {
    return RequestMainService.post(`/admin/suppliers`, { ...data });
  }

  static async swapSupplier(supplierId: number, type: string) {
    return RequestMainService.put(`/admin/suppliers/${supplierId}/swap/${type}`);
  }

  static async getListGameOrders(params: any = {}) {
    return RequestMainService.get(`/admin/order-game`, params).then(({ result }) => ({
      count: result?.total,
      data: result?.data,
      totalAmount: result?.totalAmount,
      totalProfit: result?.totalProfit,
      totalUserOrder: result?.totalUserOrder,
    }));
  }

  static async getGameList(params: any = {}) {
    return RequestMainService.get(`/admin/games`, params).then(({ result }) => ({
      count: result?.total,
      data: result?.data,
    }));
  }

  static async getCategoryList() {
    return RequestMainService.get("/get-all-category");
  }

  static async getSupplierList() {
    return RequestMainService.get("/get-all-supplier");
  }

  static async CreateGame(payload: any) {
    return RequestMainService.post(`/admin/games`, payload);
  }

  static async getGameDetail(gameId: any) {
    return RequestMainService.get(`/admin/games/${gameId}`);
  }

  static async EditGame(payload: any, gameId: any) {
    return RequestMainService.put(`/admin/games/${gameId}`, payload);
  }

  static async SwapGame(gameId: any, type: any) {
    return RequestMainService.put(`/admin/games/${gameId}/swap/${type}`);
  }

  static async ActiveGame(gameId: any, value: any) {
    return RequestMainService.put(`/admin/games/${gameId}/${value}`);
  }

  static async getListRole() {
    return RequestMainService.get(`/admin/user-roles`).then((res) => {
      return res.result.userRoles;
    });
  }

  static async getReferralSponsor(userId: any, numberOflevels: any, payload?: any) {
    return RequestMainService.get(`/admin/parents/${numberOflevels}/${userId}`, payload).then(({ result }) => ({
      count: result?.total,
      data: result?.data,
    }));
  }

  static async getListSupportCenter(payload: any) {
    return RequestMainService.get("/admin/support", payload).then(({ result }) => ({
      count: result?.total,
      data: result?.data,
    }));
  }

  static async getAtmData() {
    return RequestMainService.get(`/admin/atm`).then(async ({ result }) => {
      let resultQuery = await RequestMainService.get(`/admin/atm/report-subscribe/today`);
      let mergedArr = Object.values(_.merge(_.keyBy(result?.data, "atmId"), _.keyBy(resultQuery?.result?.data, "atmId")));
      return {
        data: mergedArr,
        count: result?.total,
      };
    });
  }

  static async getDailyProfitByAtmId(atmId: number, paramaterQuery: any) {
    return RequestMainService.get(`/admin/atm/${atmId}/profit`, paramaterQuery);
  }

  static async getSubscriberLookupByAtmId(atmId: number, param: any) {
    return RequestMainService.get(`/admin/atm/${atmId}/subscribe/today`, param);
  }

  static async updateProfitByDay(payload: any) {
    return RequestMainService.post(`/admin/atm-profit`, payload);
  }

  static async getCommissionSettingList(params: any) {
    if (params["fromDate"]) params["fromDate"] = moment(params["fromDate"]).toISOString();
    if (params["toDate"]) params["toDate"] = moment(params["toDate"]).toISOString();
    return RequestMainService.get(`/admin/commission-setting`, params);
  }

  static async submitUserToDisableCommision(payload: any) {
    return RequestMainService.post(`/admin/commission-setting`, payload);
  }

  static async removeUserToEnableCommission(userId: any) {
    return RequestMainService.put(`/admin/commission-setting/${userId}/remove`);
  }

  static async getBundleVolumeList(params: any) {
    if (params["fromDate"]) params["fromDate"] = moment(params["fromDate"]).toISOString();
    if (params["toDate"]) params["toDate"] = moment(params["toDate"]).toISOString();
    return RequestMainService.get(`/admin/packages/children`, params);
  }

  static async moveBranch(params: any) {
    return RequestMainService.post("/admin/move-tree", params);
  }

  static async getOrderList(params: any) {
    if (params.fromDate) params.fromDate = moment(params.fromDate).toISOString();
    if (params.toDate) params.toDate = moment(params.toDate).toISOString();
    return RequestMainService.get("/admin/order", params);
  }

  static async getAffiliateDetail(userId: any) {
    return RequestMainService.get(`/tree-detail/${userId}`);
  }

  static async closeBundle(userId: any) {
    return RequestMainService.put(`/admin/close-bundle/${userId}`);
  }

  static async getListCommissionHistoryFranchise(params: any = {}) {
    return RequestMainService.get(`/admin/commission-histories`, params).then(({ result }) => ({
      count: result.total,
      data: result.data,
      totalAmount: result?.totalAmount,
    }));
  }

  static async getListWithdrawalHistoryFranchise(params: any = {}) {
    return RequestMainService.get(`/admin/withdraw-histories`, params).then(({ result }) => ({
      count: result.total,
      data: result.transactions,
      totalAmount: result?.totalValue,
    }));
  }

  static async getListDepositHistoryFranchise(params: any = {}) {
    return RequestMainService.get(`/admin/deposit-histories`, params).then(({ result }) => ({
      count: result.total,
      data: result.transactions,
      totalAmount: result?.totalValue,
    }));
  }

  static async transferTicketBonus(payload: any) {
    return RequestMainService.post(`/admin/transfer-ticket`, payload);
  }

  static async depositManual(transactionHash: string) {
    return RequestMainService.post(`/admin/deposit/manual`, {
      transactionHash,
    });
  }

  // Get internal address info ~ 2mau ~ 240527 (req#124)
  static async getInternalWallet(params: any = {}) {
    return RequestMainService.get(`/admin/internal-wallet`, params).then(({ result }) => ({
      data: result.data,
      total: result.total,
    }));
  }

  static async getG4SearchList(params: any) {
    return RequestMainService.get(`/admin/g4-list`, params);
  }

  static async getBridgeSystemV2Config(params: any) {
    return RequestMainService.get(`/admin/bridge-system-v2-setting`, params);
  }

  static async editBridgeSystemV2Config(id: number, payload: any) {
    return RequestMainService.put(`/admin/bridge-system-v2-setting/${id}`, payload);
  }

  static async getBridgeSystemV2FilterStatusConfig() {
    return RequestMainService.get(`/admin/bridge-system-v2-setting/filter-button`);
  }

  static async setBridgeSystemV2FilterStatusConfig(value: boolean) {
    return RequestMainService.put(`/admin/bridge-system-v2-setting/filter-button/${value}`);
  }

  static async getBridgeSystemV2(params: any) {
    return RequestMainService.get(`/admin/symbols/price`, params);
  }

  static async getOrderBridgeSystemV2List(params: any) {
    if (params.fromDate) params.fromDate = moment(params.fromDate).toISOString();
    if (params.toDate) params.toDate = moment(params.toDate).toISOString();
    return RequestMainService.get("/admin/bridge-system-v2/orders", params);
  }

  static async getMecDashboardPool() {
    return RequestMainService.get(`/admin/mec-mining-pool-config`);
  }

  static async getPoolDetailById(id: any, params: any = {}) {
    return RequestMainService.get(`/admin/mec-mining/${id}/subscribes`, params).then(({ result }) => ({
      data: [
        ...result.data,
        {
          userName: "Total",
          mecAmount: result.total.totalMecAmount,
          mainAmount: result.total.totalUsdtAmount,
          mecUAmount: result.total.totalMecUAmount,
          mainUAmount: result.total.totalMainUAmount,
          startDate: null,
          endDate: null,
          totalMecReward: null,
          percentage: result.total.totalPercentage,
        },
      ],
      count: result.data.length,
    }));
  }
}
